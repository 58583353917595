import { nextTick } from 'vue'
import { useNuxtApp, useRouter, useRoute } from '#app'
import { useCartStore } from '@/stores/cart'
import { useActivationStore } from '@/stores/activation'
import { useUserStore } from '@/stores/user'
import { useEmitterStore } from '@/stores/emitter'
import { useCurrentLocale } from '@/composables/i18n/useCurrentLocale'
import { useAnalytics } from '@/composables/Analytics/useAnalytics'
import { useUserApi } from '@/composables/Api/useUserApi'

const { request } = useApiRequest()

export function useLogin() {
  const router = useRouter()
  const route = useRoute()
  const localePath = useLocalePath()
  const cartStore = useCartStore()
  const { $i18n } = useNuxtApp()

  const emailOrPhone = ref(null)
  const password = ref(null)
  const withRedirect = ref(false)
  const error = ref(null)
  const isLoading = ref(false)
  const compact = ref(false)
  const userStore = useUserStore()

  const loginMx = async provider => {
    try {
      const emitter = useEmitterStore()

      if (!isLoading.value && emailOrPhone.value && password.value) {
        error.value = null
        const response = await request({
          req: useUserApi().login(emailOrPhone.value, password.value),
        })
        if (response?.user !== null && response?.user !== undefined) {
          await userStore.login(response.token, response.user)
          if (compact.value) {
            emitter.emit('proceed-to-payment')
          } else {
            const redirect_url = route.query.redirect || '/'
            if (withRedirect.value) {
              await nextTick(() => {
                setTimeout(() => {
                  router.push({ path: redirect_url })
                }, 500)
              })
            }
          }
          loginEventToAnalytics('phone number')
        } else {
          const { country } = useCurrentLocale()
          error.value =
            $i18n.t(`backendError.${response?.message}`, {
              phone: country?.phoneNumber,
            }) ||
            $i18n.t('backendError.unknown', {
              phone: country?.phoneNumber,
            })
          return error.value
        }
      } else if (!isLoading.value && provider) {
        error.value = null
        await userStore.login(provider.token, provider.user)
        // if (compact.value) {
        //   emit('proceed-to-payment')
        // } else {
        //   const route = useRoute()
        //   let redirect_url = route.query.redirect || '/'
        //   if (withRedirect.value) {
        //     await nextTick(() => {
        //       setTimeout(() => {
        //         router.push({
        //           path: redirect_url,
        //         })
        //       }, 500)
        //     })
        //   }
        // }
        loginEventToAnalytics('google')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const goToResetPassword = async () => {
    if (!emailOrPhone.value) {
      error.value = $i18n.t('backendError.phone_input_empty')
      return
    }

    const success = await request({
      req: useUserApi().forgotPassword(emailOrPhone.value),
    })

    if (success) {
      const activationStore = useActivationStore()
      activationStore.setEmailOrPhone(emailOrPhone.value)
      activationStore.setForgotPassword(true)

      if (!compact.value) {
        router.push(localePath('login-activation'))
      }
    } else {
      const { country } = useCurrentLocale()

      error.value = $i18n.t('backendError.unknown', {
        phone: country?.phoneNumber,
      })
    }
  }

  const loginInCart = async provider => {
    const { $cart } = useNuxtApp()
    if (!compact.value) {
      await loginMx(provider)
      if (!error.value) {
        $cart()
          .synchronize(userStore)
          .then(() => {
            router.push(localePath('cart-checkout'))
          })
          .catch(error => {
            if (error.response) {
              cartStore.setSyncError(error.response.data)
            } else {
              cartStore.setSyncError(error)
            }
            router.replace(localePath('cart'))
          })
      }
    }
  }

  const loginEventToAnalytics = method => {
    const userStore = useUserStore()
    const { analytics, events } = useAnalytics()

    analytics.sendEvent(events.LOGIN, {
      method: method,
      form_user_type: userStore.isPro ? 'pro' : 'individual',
    })
    analytics.sendEvent(events.LOGIN_STATUS, userStore)
  }

  return {
    emailOrPhone,
    password,
    withRedirect,
    error,
    loginMx,
    goToResetPassword,
    loginInCart,
    compact,
  }
}
